/* src/styles/globals.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Glacial Indifference';
  src: url('./fonts/GlacialIndifference-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Glacial Indifference', sans-serif;
}

/* Custom styles for react-image-lightbox */
.ril__toolbar {
  background: transparent !important;
  border: none !important;
}

.ril__toolbarSide {
  background: transparent !important;
}

.ril__toolbarItem {
  background: transparent !important;
}

.custom-close-button {
  background-color: red;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1500; /* Ensure it's in front */
}

.ril__builtinButton {
  background: transparent !important;
  border: none !important;
  color: white;
  cursor: pointer;
}

.ril__navButtons {
  background: transparent !important;
  border: none !important;
}

.ril__navButtonPrev,
.ril__navButtonNext {
  background: transparent !important;
  border: none !important;
  color: white !important;
  cursor: pointer;
  z-index: 1200; /* Ensure it's in front */
}

.ril__navButtonPrev:before,
.ril__navButtonNext:before {
  content: '‹'; /* Left and right arrow symbols */
  font-size: 40px;
  color: white;
  background: transparent;
  border: none;
}

.ril__navButtonNext:before {
  content: '›'; /* Right arrow symbol */
}
